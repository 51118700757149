/* src/RangeSliderNovo.css */
.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider {
    position: relative;
    width: 300px;
    height: 8px;
    margin: 20px 0;
}

.slider-input {
    position: absolute;
    width: 100%;
    pointer-events: none; /* Permite clicar nos sliders mesmo que os inputs estejam atrás */
}

.slider-input-min {
    z-index: 2;
}

.slider-input-max {
    z-index: 1;
}

.slider-track {
    position: relative;
    height: 8px;
    background-color: #ddd;
    border-radius: 4px;
    margin-top: 10px;
}

.slider-range {
    position: absolute;
    height: 8px;
    background-color: #007bff;
    border-radius: 4px;
}

.tooltip {
    position: absolute;
    bottom: 20px;
    background: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    transform: translateX(-50%);
    white-space: nowrap;
}
